<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Statuts</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <SimpleAccordion class="mb-3">
                <template v-slot:accordionTitle>Ajouter un statut</template>
                <template v-slot:accordionContent>
                    <div class="m-3">
                        <label for="newStatusValue" class="form-label">Valeur du statut</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="newStatusValue"
                            placeholder="exemple: Chantier terminé"
                            v-model="newStatus.value"
                        />
                        <label class="form-label"
                            >Public
                            <small style="display: block"
                                >Un statut public est visible par toutes les parties, au contraire un statut privé ne
                                sera visible que par vous et votre équipe</small
                            ></label
                        >
                        <select class="form-select mb-3" v-model="newStatus.isPublic">
                            <option :value="true">Oui</option>
                            <option :value="false">Non</option>
                        </select>
                        <div class="text-end">
                            <button class="btn btn-primary" @click="createStatus">
                                Créer le statut
                                <div
                                    v-if="newStatusIsSending"
                                    class="uk-margin-left uk-inline"
                                    uk-spinner="ratio:0.5"
                                ></div>
                            </button>
                        </div>
                    </div>
                </template>
            </SimpleAccordion>
            <vue-good-table
                :is-loading="statusListIsUpdating"
                :columns="columns"
                :rows="status"
                :search-options="{
                    enabled: true,
                }"
                :pagination-options="pagination"
                styleClass="vgt-table striped bordered condensed"
            >
                <template v-slot:table-column="props">
                    <div v-if="props.column.field === 'isPublic'">
                        {{ props.column.label }}
                        <small style="display: block">Un statut public est visible par toutes les parties</small>
                    </div>
                    <div v-else>
                        {{ props.column.label }}
                    </div>
                </template>
                <template v-slot:table-row="props" class="vgt-center-align">
                    <div v-if="props.column.field === 'value'">
                        <input
                            type="text"
                            class="form-control"
                            v-model="props.row.value"
                            @keyup="setEditPendingStatus(props.row)"
                            @keydown="setEditPendingStatus(props.row)"
                            @focusout="setEditPendingStatus(props.row)"
                            @focusin="setEditPendingStatus(props.row)"
                        />
                    </div>
                    <div v-else-if="props.column.field === 'isPublic'">
                        <select
                            class="form-select"
                            v-model="props.row.isPublic"
                            @change="setEditPendingStatus(props.row)"
                        >
                            <option :value="true">Oui</option>
                            <option :value="false">Non</option>
                        </select>
                    </div>
                    <div v-else-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="editStatus(props.row)"
                            :disabled="!props.row.editPending || props.row.remoteEditIsPending"
                        >
                            Enreg. les modifications
                            <div
                                v-if="props.row.remoteEditIsPending"
                                class="uk-margin-left uk-inline"
                                uk-spinner="ratio:0.5"
                            ></div>
                        </button>
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-warning dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                :disabled="props.row.deleteIsPending"
                            >
                                Supprimer
                                <div
                                    v-if="props.row.deleteIsPending"
                                    class="uk-margin-left uk-inline"
                                    uk-spinner="ratio:0.5"
                                ></div>
                            </button>
                            <ul class="dropdown-menu bg-danger">
                                <li>
                                    <a class="dropdown-item text-white" href="#" @click="deleteStatus(props.row)"
                                        >Confirmer</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import { mapActions, mapMutations, mapState } from 'vuex'
import SimpleAccordion from '@/components/SimpleAccordion'

export default {
    name: 'Status',
    components: { SimpleAccordion },
    data: () => ({
        columns: [
            {
                label: 'Valeur',
                field: 'value',
            },
            {
                label: 'Public',
                field: 'isPublic',
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            },
        ],
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
        newStatus: {
            value: '',
            isPublic: true,
        },
        newStatusIsSending: false,
    }),
    computed: {
        ...mapState('status', { status: 'list', statusListIsUpdating: 'listIsUpdating' }),
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('status', ['updateStatusList']),
        ...mapMutations('status', ['UPDATE_STATUS']),
        editStatus(row) {
            let status = {
                id: row.id,
                value: row.value,
                isPublic: row.isPublic,
            }

            this.$set(row, 'remoteEditIsPending', true)

            backendApi
                .editStatus(store.state.login.user.token, status)
                .then((data) => {
                    this.$set(row, 'editPending', true)
                    this.$delete(row, 'remoteEditIsPending')
                    this.UPDATE_STATUS(data)
                    this.addNotification({ status: 'success', message: 'Statut modifié' })
                })
                .catch(backendApi.notificationOnError)
        },
        deleteStatus(row) {
            this.$set(row, 'deleteIsPending', true)
            backendApi
                .deleteStatus(store.state.login.user.token, row.id)
                .then((data) => {
                    this.$delete(row, 'deleteIsPending')
                    this.UPDATE_STATUS(data)
                    this.addNotification({ status: 'success', message: 'Statut supprimé' })
                })
                .catch(backendApi.notificationOnError)
        },
        setEditPendingStatus(row) {
            this.$set(row, 'editPending', false)
            let originalRow = this.status[row.originalIndex]
            if (row.isPublic !== originalRow.isPublic || row.value !== originalRow.value) {
                this.$set(row, 'editPending', true)
            }
        },
        createStatus() {
            this.newStatusIsSending = true
            backendApi
                .createStatus(store.state.login.user.token, this.newStatus)
                .then((data) => {
                    this.newStatus = {
                        value: '',
                        isPublic: true,
                    }
                    this.UPDATE_STATUS(data)
                    this.addNotification({ status: 'success', message: 'Statut créé' })
                })
                .catch(backendApi.notificationOnError)
                .finally(() => {
                    this.newStatusIsSending = false
                })
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('status/updateStatusList')
        next()
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('status/updateStatusList')
        next()
    },
    mounted() {},
}
</script>

<style scoped></style>
